import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const useVerse = (verse_num) => {
  const [verse, setVerse] = useState([]);

  //console.log("verse_num: " + verse_num);
  useEffect(() => {
    projectFirestore.collection('verses')
      .where('verse_num', '==', "" + verse_num)
      .get()
      .then(snap => {
        let documents = [];
        snap.forEach(doc => {
          documents.push({...doc.data(), id: doc.id});
        });

        if (documents.length > 0) {
          setVerse(documents[0]);
        }
        else {
          // TODO: handle error
        }
      });

    //return () => unsub();
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, [verse_num]);

  return { verse };
}

export default useVerse;