import React from 'react';

const Message = (props) => {
    const { message } = props;

    console.log(`message = ${message}`);

    return (
        <div className="message2">
            <h2 >
                {message}
            </h2>
        </div>
    )
}

export default Message;