import React from 'react';

const Title = () => {
  return (
    <div className="title">
      <div className="topRow">&nbsp;</div>
      <div className="logo">&nbsp;</div>
      <div className="bottomRow">&nbsp;</div>
      <div className="message">
        <h2>CEC Annual Bible Verse Drawing</h2>
        <h2>生命堂年度領取聖經經文</h2>
      </div>
    </div>
  )
}

export default Title;