import React, {useState, useRef, useEffect} from 'react';
import {projectAnalytics} from '../firebase/config';
import useVerseCount from '../hooks/useVerseCount';
import useVerse from '../hooks/useVerse';
import logo from '../images/favicon-32x32.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons'


const VersePicker = (props) => {
    const [verseNum, setVerseNum] = useState(0);
    const [countDown, setCountDown] = useState(0);

    const pickNewVerse = () => {
        const newSelectedVerse = Math.floor(Math.random() * verseCount);
        console.log("newSelectedVerse: " + newSelectedVerse);
        if (projectAnalytics) {
            projectAnalytics.logEvent("pick_verse", {verse_num: newSelectedVerse});
        }
        setVerseNum(newSelectedVerse); // 238, 206
        setCountDown(30);
        const verseButton = document.getElementById("verseButton");
        verseButton.disabled = true;
    }

    
    const verseCount = useVerseCount();
    //console.log("verseCount=" + verseCount);
  
    const { verse } = useVerse(verseNum);
  
    //console.log(verse);


    const canvasRef = useRef(null);

    // eslint-disable-next-line
    function writePassage(context, text, x, y, maxWidth, lineHeight) {
        context.fillStyle = "#e0e0dd";
        context.font = "20px Helvetica Neue";

        return wrapText(context, verse.passage.en, x, y, maxWidth, lineHeight);
    }

    // eslint-disable-next-line
    function writePassageZh(context, text, x, y, maxWidth, lineHeight) {
        context.fillStyle = "#e0e0dd";
        context.font = "20px Helvetica Neue";

        //console.log(text);
        return wrapTextZh(context, text, x, y, maxWidth, lineHeight);
    }

    function writeReference(context, text, y, maxWidth, lineHeight) {
        context.font = "italic 14px  Helvetica Neue";
        context.fillStyle = "#E1731A";
        var metrics = context.measureText(text);

        context.fillText(text, maxWidth - metrics.width, y);
        return y + lineHeight;
    }

    function wrapText(context, text, x, y, maxWidth, lineHeight) {
        var words = text.split(" ");
        var line = '';
        //console.log(words);
        for(var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = context.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            context.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          }
          else {
            line = testLine;
          }
        }
        context.fillText(line, x, y);

        return y + lineHeight;
    }

    function wrapTextZh(context, text, x, y, maxWidth, lineHeight) {
        var metrics = context.measureText(text);
        var len = text.length;

        //console.log(metrics);
        //console.log(len);

        var charWidth = Math.floor(metrics.width / len);

        //console.log(charWidth);

        var numCharsPerLine = Math.floor(maxWidth / charWidth) - 2;

        //console.log(numCharsPerLine);

        var i = 0;

        while (i < len) {
            //console.log(text.slice(i, i + numCharsPerLine))
            context.fillText(text.slice(i, i + numCharsPerLine), x, y);
            y += lineHeight;
            i += numCharsPerLine;
        }

        return y;
    }
    
    useEffect(() => {
        //console.log("canvasRef:", canvasRef);

        if (countDown > 0) {
            const timer1 = setTimeout(() => {
                setCountDown((prevValue) => prevValue - 1);
                //console.log(countDown);
                if (countDown === 1) {
                    const verseButton = document.getElementById("verseButton");
                    verseButton.disabled = false;
                }
            }, 1000);

            return () => {
                //console.log(timer1);
                clearTimeout(timer1);
            }
        }
    }, [countDown]);
      
    useEffect(() => {
        //console.log("canvasRef:", canvasRef);

        const canvas = canvasRef.current
        if (canvas) {
            //console.log("Drawing");
            var ctx = canvas.getContext("2d");

            if (verse) {
                ctx.fillStyle = "#0f5b69";
                ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                
                const logo_img = document.getElementById("min_logo");
                ctx.drawImage(logo_img, 10, 305);
                ctx.fillStyle = "#e0e0dd";
                ctx.font = "10px Helvetica Neue";
                ctx.fillText((new Date()).toDateString(), 50, 333);

                var yOffset = 30;
                //console.log(yOffset);
                yOffset = writePassage(ctx, verse.passage.en, 10, yOffset, 480, 22);
                //console.log(yOffset);
                yOffset = writeReference(ctx, verse.reference.en, yOffset, 480, 22);
                //console.log(yOffset);

                yOffset += 5;

                yOffset = writePassageZh(ctx, verse.passage.zh, 10, yOffset, 480, 22);
                //console.log(yOffset);
                //yOffset =
                writeReference(ctx, verse.reference.zh, yOffset, 480, 22);
                //console.log(yOffset);

            }
        }
      }, [verse, writePassage, writePassageZh, countDown])

    function downloadVerse(e) {
        const fileName = "annual-verse.png";
        const canvas = document.querySelector('canvas');

        if (projectAnalytics) {
            projectAnalytics.logEvent("download_verse", {verse_num: verseNum});
        }

        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), fileName);
            e.preventDefault();
        } else {
            const anchor = document.querySelector('a');
            anchor.setAttribute('download', fileName);
            anchor.setAttribute('href', canvas.toDataURL());
        }
    }



    return (
        <div className="versePicker">
            <div className="panel">
                <button id="verseButton" onClick={pickNewVerse}><FontAwesomeIcon className="avoidClicks" icon={faQuestionCircle} /> Pick a verse 領取經文 {countDown > 0 ? "(" + countDown + ")" : ""}</button>
            </div>
            {verse.reference &&
                <div>
                    <div className="verse">
                        <div className="passage">
                            {verse.passage.en}
                        </div>
                        <div className="reference">
                            {verse.reference.en}
                        </div>
                    </div>
                    <div className="verse">
                        <div className="passage">
                            {verse.passage.zh}
                        </div>
                        <div className="reference">
                            {verse.reference.zh}
                        </div>
                    </div>
                    <canvas className="verseCanvas" width="500" height="350"  ref={canvasRef} {...props}/>
                    <br/>
                    <a href="#/" download="image.png" onClick={downloadVerse}><button><FontAwesomeIcon icon={faDownload} /> Download 下載</button></a>
                    <img id="min_logo" src={logo} alt="logo"/>
                </div>
                
         

            }


        </div>
    );

}

export default VersePicker;