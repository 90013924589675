import { useState, useEffect } from 'react';
import { projectFirestore } from '../firebase/config';

const useVerseCount = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    projectFirestore.collection('verses')
      //.where('__name__', '==', 'SUMMARY')
      .doc("SUMMARY")
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log("setting count to ", doc.data().count)
          setCount(doc.data().count);
        }
        else {
          // TODO: handle error
        }

      });

    //return () => unsub();
    // this is a cleanup function that react will run when
    // a component using the hook unmounts
  }, []);

  //console.log("count=" + count);

  return count;
}

export default useVerseCount;