import React, {useState, useEffect} from 'react';

import Title from './components/Title';
import Message from './components/Message';

import VersePicker from './components/VersePicker'
import Footer from './components/Footer'
import {remoteConfig} from './firebase/config';



function App() {
  const [loading, setLoading] = useState(true);
  const [unavailable, setUnavailable] = useState('');

  console.log(`unavailable = ${unavailable}`)

  useEffect(() => {
    remoteConfig.fetchAndActivate()
    .then(() => {
      console.log("site_unavailable_message: |" + remoteConfig.getString("site_unavailable_message") + "|");
      setUnavailable(remoteConfig.getString("site_unavailable_message"));
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
    });
  })


  return (
    <div className="App">
      <Title/>
      {loading || unavailable !== '' ? <Message message={unavailable}/> : <VersePicker/> }
      <Footer/>
    </div>
  );
}

export default App;
