import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/performance';
import 'firebase/remote-config';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC1PLuZ43j4RXkgr7X4fGmuypUGQkmENPo",
    authDomain: "bibleversedrawing.firebaseapp.com",
    databaseURL: "https://bibleversedrawing.firebaseio.com",
    projectId: "bibleversedrawing",
    storageBucket: "bibleversedrawing.appspot.com",
    messagingSenderId: "1088655216598",
    appId: "1:1088655216598:web:f3bc4a1edc21b0cfa05e77",
    measurementId: "G-8HGB21GQMR"
  };

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  const projectAnalytics  = firebase.analytics();
  const projectPerformance  = firebase.performance();

  const projectFirestore = firebase.firestore();
  if (window.location.hostname === "localhost") {
    console.log("Connecting the firestore emulator")
    //projectFirestore.useEmulator("localhost", 8080);
  }

  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 3600000,
  };

  remoteConfig.defaultConfig = ({
    'site_unavailable_message': 'Welcome'
  });

  export {projectFirestore, projectAnalytics, projectPerformance, remoteConfig};
